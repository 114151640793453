import React from "react"
import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"

export default class Layout extends React.Component {
  render() {
    return (
      <div>
        <SEO title={this.props.title} />
        <Header key="custom-header" />
        <section className="hommerfitness-container">
          <div key={this.props.title}>{this.props.children}</div>
        </section>
        <div className="row">
          <div className="institutional-container col s12">
            <div className="global-inner">
              <h5>Siga a Hommer!</h5>

              <div className="col s12 m6 l6 youtube-institutional institutional-block">
                <div className="col s12 block-content">
                  <iframe
                    title="YouTube Institucional"
                    width="100%"
                    height="214"
                    src="https://www.youtube.com/embed/1DOC44oYxZs?rel=0"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>

              <div className="col s12 m6 l6 facebook-institutional institutional-block">
                <div className="col s12 block-content">
                  <iframe
                    title="Facebook"
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fhommerfitness&tabs&width=340&height=181&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    width="340"
                    height="181"
                    style={{ border: "none", overflow: "hidden" }}
                    scrolling="no"
                    frameBorder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                    className="fb-page"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line-divider"></div>
        <Footer />
      </div>
    )
  }
}
