import React from "react"
import { Link } from "gatsby"
import "materialize-css/dist/css/materialize.min.css"
import $ from "jquery"
import MenuIcon from "@material-ui/icons/Menu"

if (typeof window !== "undefined") {
  require("materialize-css/dist/js/materialize.min.js")
}

class Header extends React.Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.M.Sidenav.init(this.Sidenav)

      let instance = window.M.Sidenav.getInstance(this.Sidenav)

      if (!instance.isOpen) {
        $(".sidenav-overlay").click()
      }
    }
  }
  render() {
    return (
      <div>
        <header>
          <ul
            ref={Sidenav => {
              this.Sidenav = Sidenav
            }}
            id="slide-out"
            className="sidenav"
          >
            <div className="logo-blank-espace"></div>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/informacoes">Informações</Link>
            </li>
            <li>
              <Link to="/sobre">A Hommer Fitness</Link>
            </li>
            <li>
              <Link to="/modalidades">Modalidades</Link>
            </li>
            <li>
              <Link to="/galeria">Galeria</Link>
            </li>
            <li>
              <Link to="/promocoes">Promoções</Link>
            </li>
            <li>
              <Link to="/eventos">Eventos</Link>
            </li>
            <li>
              <Link to="/contato">Contato</Link>
            </li>
          </ul>
          <div className="navbar-fixed">
            <nav>
              <div className="global-inner">
                <ul id="nav-mobile" className="right menu-desktop">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/informacoes">Informações</Link>
                  </li>
                  <li>
                    <Link to="/sobre">A Hommer Fitness</Link>
                  </li>
                  <li>
                    <Link to="/modalidades">Modalidades</Link>
                  </li>
                  <li>
                    <Link to="/galeria">Galeria</Link>
                  </li>
                  <li>
                    <Link to="/promocoes">Promoções</Link>
                  </li>
                  <li>
                    <Link to="/eventos">Eventos</Link>
                  </li>
                  <li>
                    <Link to="/contato">Contato</Link>
                  </li>
                </ul>

                <a
                  href="#!"
                  data-target="slide-out"
                  className="right sidenav-trigger"
                >
                  <i className="material-icons menu-open">
                    <MenuIcon />
                  </i>
                </a>
              </div>
            </nav>
          </div>
        </header>
        <section className="hommerfitness-logo">
          <div className="global-inner">
            <Link to="/">
              <div className="logo-img"></div>
            </Link>
          </div>
        </section>
      </div>
    )
  }
}

export default Header
